export default [
  {
    title: 'Tableau de bord',
    route: 'transporteur.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Offre de fret',
    icon: 'PackageIcon',
  },
  {
    title: 'Disponible',
    icon: 'TruckIcon',
    route: 'transporteur.disponible',
  },
  {
    header: 'Devis',
    icon: 'ListIcon',
  },
  {
    title: 'Liste de devis',
    icon: 'ListIcon',
    children: [
      {
        title: 'Soumis',
        color: 'primary',
        route: 'transporteur.devis-soumis',

      },
      {
        title: 'Sous contrats',
        color: 'warning', 
        route: 'transporteur.sous-contrat',

      },
      {
        title: 'Clôturés', 
        color: 'success',
        route: 'transporteur.devis-cloture',

      },
      {
        title: 'Annulées', 
        color: 'danger',
        route: 'transporteur.devis-annule',

      },
    ],
  },
  {
    header: 'Contrats',
    icon: 'PackageIcon',
  },
  {
    title: 'Liste des contrats',
    icon: 'ListIcon',
    children: [
      {
        title: 'Contrats actifs',
        icon: 'FileTextIcon',
        color: 'primary',
        route: 'transporteur.contrat-actif',

      },
      {
        title: 'Contrats clôturés',
        icon: 'FileTextIcon',
        color: 'success',
        route: 'transporteur.contrat-cloture',
      },
    ],
  },
  {
    header: 'Moyens de transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter',
    icon: 'PlusIcon',
    route: 'apps-email',
  },
  {
    title: 'Liste',
    icon: 'ListIcon',
    route: 'transporteur.moyen-de-transport',

  },
  {
    header: 'Disponibilité',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter',
    icon: 'PlusIcon',
    route: 'apps-email',
  },
  {
    title: 'Mes disponibilités',
    icon: 'ListIcon',
    route: 'transporteur.disponilities',
  },
  {
    header: 'Tracking',
    icon: 'PackageIcon',
  },
  {
    title: 'Rechercher',
    icon: 'MapPinIcon',
    route: 'transporteur.tracking',
  },
  {
    header: 'Validation',
    icon: 'PackageIcon',
  },
  {
    title: 'Profil',
    icon: 'UserIcon',
    route: 'apps-email',
  },
]
